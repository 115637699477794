import { Injectable } from '@angular/core';

import { Http, HttpUploadFileResult } from '@capacitor-community/http';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Plugins } from '@capacitor/core';
import { HelperService } from './helper.service';
import { Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';

export interface ApiImage {
  _id: string;
  name: string;
  createdAt: Date;
  url: string;
}

@Injectable({
  providedIn: 'root'
})

export class DataService {
  dev_mode = true;
  root = 'https://admin.bv-osteopathie.de/';
  root_prod = 'https://admin.bv-osteopathie.de/';
  root_dev = 'https://bvo-admin.mmc-entwicklung.de/';
  url = this.root + 'api/';
  uploads = this.root + 'uploads/';
  toast: any;
  token: any;
  user_id: any;
  user_data: any;

  constructor(public router: Router, private httpClient: HttpClient, private h: HelperService) {
    if(this.dev_mode) {
      this.root = this.root_dev;
      this.url = this.root + 'api/';
      this.uploads = this.root + 'uploads/';   
    } 
  }

  ngOnInit() {}

  changeMode() {
    if(this.dev_mode) {
      this.dev_mode = false;
      this.root = this.root_prod;
      this.url = this.root + 'api/';
      this.uploads = this.root + 'uploads/';     
      this.showToast("Entwicklermodus deaktiviert.");
    } else {
      this.dev_mode = true;
      this.root = this.root_dev;
      this.url = this.root + 'api/';
      this.uploads = this.root + 'uploads/';    
      this.showToast("Entwicklermodus aktiviert.");      
    }
  }

  async restoreData() {
    var token = await Preferences.get({ key: 'token' });
    if(token) { this.token = token.value; }
    var user_id = await Preferences.get({ key: 'user_id' });
    if(user_id) { this.user_id = user_id.value; }
    var user_data = await Preferences.get({ key: 'user_data' });
    if(user_data) { this.user_data = user_data.value; }
    console.log("Should get it on every load! : " + this.token + " für " + this.user_id);
    if(!this.token) {
      alert("Kein Login Token - Weiterleitung zum Login");
      this.router.navigate(['home']);
    }
  }

  async storeData(user_id, token) {
    this.token = token;
    this.user_id = user_id;
    await Preferences.set({ key: 'token', value: token });
    await Preferences.set({ key: 'user_id', value: user_id });
    console.log("Values have been stored : " + token + " für " + user_id);
  }

  async removeData() {
    this.token = "";
    this.user_id = 0;
    this.user_data = null;    

    await Preferences.remove({
      key: 'token'
    });
    await Preferences.remove({
      key: 'user_id'
    });

    this.router.navigate(['/']); 
    this.showToast("Sie wurden erfolgreich ausgeloggt.");
  }

  get(url) {
    var self = this;
    console.log("AJAX Request GET to " + url);
    let result = this.httpClient.get(url).pipe(
      map(results => {
        console.log('RAW: ', results);
        return results;
      }),
      catchError((error) => {
        console.log(error);
        return error;
      })
    );

    return result;
  }

  handleError() {
    alert("Error");
  }

  post(url, data) {
    var self = this;

    let result = this.httpClient.post(url, data).pipe(
      map(results => {
        console.log('RAW: ', results);
        return results;
      })
    );
    return result;
  }

  /**********************
   * Documents
  **********************/
  getPrints(): Observable<any> {
    return this.get(this.url + "marketingmaterials?token=" + this.token + "&id=" + this.user_id);
  }

  getCertificates(): Observable<any> {
    return this.get(this.url + "files?token=" + this.token + "&id=" + this.user_id);
  }

  getTemplates(): Observable<any> {
    return this.get(this.url + "documents?filter[category]=template&token=" + this.token + "&id=" + this.user_id);
  }

  getDownloads(): Observable<any> {
    return this.get(this.url + "documents?filter[category]=document&token=" + this.token + "&id=" + this.user_id);
  }

  getOrders(): Observable<any> {
    return this.get(this.url + "marketingmaterials/orderhistory?token=" + this.token + "&id=" + this.user_id);
  }

  getBills(): Observable<any> {
    return this.get(this.url + "files?token=" + this.token + "&id=" + this.user_id);
  }

  getRecordings(): Observable<any> {
    return this.get(this.url + "recording?token=" + this.token + "&id=" + this.user_id);
  }

  /**********************
   * Quizzes
  **********************/
  getQuizzes(): Observable<any> {
    return this.get(this.url + "quiz?token=" + this.token + "&id=" + this.user_id);
  }

  getMagazines(): Observable<any> {
    return this.get(this.url + "magazine?token=" + this.token + "&id=" + this.user_id);
  }

  /**********************
   * Podcasts
  **********************/
  getPodcasts(): Observable<any> {
    return this.get(this.url + "podcast?token=" + this.token + "&id=" + this.user_id);
  }

  getPodcast(id): Observable<any> {
    return this.get(this.url + "podcast/" + id + "?token=" + this.token + "&id=" + this.user_id);
  }

  /**********************
   * Events
  **********************/
  getEvents(): Observable<any> {
    return this.get(this.url + "event?token=" + this.token + "&id=" + this.user_id);
  }

  getEvent(id): Observable<any> {
    return this.get(this.url + "event/" + id + "?token=" + this.token + "&id=" + this.user_id);
  }

  registerEvent(id): Observable<any> {
    return this.get(this.url + "event/" + id + "/register?token=" + this.token + "&id=" + this.user_id);
  }

  registerWebinar(id): Observable<any> {
    return this.get(this.url + "webinar/" + id + "/register?token=" + this.token + "&id=" + this.user_id);
  }

  cancelWebinar(id): Observable<any> {
    return this.get(this.url + "webinar/" + id + "/cancel?token=" + this.token + "&id=" + this.user_id);
  }

  /**********************
   * Posts
  **********************/
  getPosts(): Observable<any> {
    return this.get(this.url + "post?token=" + this.token + "&id=" + this.user_id);
  }

  getPost(id): Observable<any> {
    return this.get(this.url + "post/" + id + "?token=" + this.token + "&id=" + this.user_id);
  }

  getNotes(): Observable<any> {
    return this.get(this.url + "note?token=" + this.token + "&id=" + this.user_id);
  }

  /**********************
   * Shop
  **********************/
  getProducts(): Observable<any> {
    return this.get(this.url + "shopproduct?token=" + this.token + "&id=" + this.user_id);
  }

  createCart(): Observable<any> {
    return this.get(this.url + "shoppingcart/create?token=" + this.token + "&id=" + this.user_id);
  }

  getCart(): Observable<any> {
    return this.get(this.url + "shoppingcart?token=" + this.token + "&id=" + this.user_id);
  }

  addProduct(id, count): Observable<any> {
    return this.post(this.url + "shoppingcart/item/add", { 
      shopproduct_id: id, 
      qty: count,
      id: this.user_id, 
      token: this.token 
    });
  }

  removeProduct(id): Observable<any> {
    return this.get(this.url + "shoppingcart/item/remove/" + id + "?token=" + this.token + "&id=" + this.user_id);
  }

  updateProduct(id, count): Observable<any> {
    return this.post(this.url + "shoppingcart/item/update/" + id, { 
      qty: count,
      id: this.user_id, 
      token: this.token 
    });
  }
  /**********************
   * Educations
  **********************/
  postNewEducation(education): Observable<any> {
    const formData = new FormData();

    if(education.file) {
      formData.append('file', education.file, `file.${education.file_ext}`);      
    }

    formData.append('name', education.name);
    formData.append('id', this.user_id);
    formData.append('date', education.date);
    formData.append('token', this.token);
    formData.append('type', education.type);
    formData.append('points_type', education.points_type);
    formData.append('comment', education.comment);
 
    return this.httpClient.post(this.url + "education", formData);
  }

  getEducations(): Observable<any> {
    return this.get(this.url + "educations" + "?token=" + this.token + "&id=" + this.user_id);
  }

  getRecommendation(): Observable<any> {
    return this.get(this.url + "education/recommendation?token=" + this.token + "&id=" + this.user_id);
  }

  /**********************
   * Tickets
  **********************/
  checkUnread(): Observable<any> {
    return this.get(this.url + "tickets/with_unread_messages?token=" + this.token + "&user_id=" + this.user_id);
  }

  postNewTicket(ticket) {
    const formData = new FormData();

    if(ticket.file) {
      formData.append('file[]', ticket.file, `myfile.${ticket.file_ext}`);
    }

    formData.append('subject', ticket.subject);
    formData.append('id', this.user_id);
    formData.append('token', this.token);
    formData.append('priority', ticket.priority);
    formData.append('category', ticket.category);
    formData.append('message', ticket.message);
 
    return this.httpClient.post(this.url + "ticket", formData);
  }

  getTicket(id): Observable<any> {
    return this.get(this.url + "ticket/" + id + "?token=" + this.token + "&user_id=" + this.user_id);
  }

  getTickets(): Observable<any> {
    return this.get(this.url + "tickets?token=" + this.token + "&id=" + this.user_id);
  }

  postAnswer(id, message): Observable<any> {
    return this.post(this.url + "ticket/" + id + "/answer", { 
      message: message, 
      id: this.user_id, 
      token: this.token 
    });
  }

  uploadImageTicket(blobData, name, ext, ticket_id) {
    const formData = new FormData();
    formData.append('file[]', blobData, `myimage.${ext}`);
    formData.append('name', name);
    formData.append('id', this.user_id);
    formData.append('token', this.token);
    formData.append('message', "Dateianhang hinzugefügt - Blob.");
 
    return this.httpClient.post(this.url + "ticket/" + ticket_id + "/answer", formData);
  }
 
  uploadImageFileTicket(file: File, ticket_id) {
    const ext = file.name.split('.').pop();
    const formData = new FormData();
    formData.append('file[]', file, `myimage.${ext}`);
    formData.append('name', file.name);
    formData.append('id', this.user_id);
    formData.append('token', this.token);
    formData.append('message', "Dateianhang hinzugefügt - File API.");
 
    return this.httpClient.post(this.url + "ticket/" + ticket_id + "/answer", formData);
  }

  /**********************
   * Profile
  **********************/
  getProfile(): Observable<any> {
    return this.get(this.url + "profile?token=" + this.token + "&id=" + this.user_id);
  }

  updateProfile(data): Observable<any> {
    const formData = new FormData();

    if(data.icon) {
      formData.append('icon',data.icon, `myfile.${data.icon_ext}`);
    }

    if(data.thumb) {
      formData.append('thumb',data.thumb, `myfile.${data.thumb_ext}`);
    }

    formData.append('email', data.email);
    formData.append('company', data.company);   
    formData.append('street', data.street);
    formData.append('city', data.city);
    formData.append('addr_addition', data.addr_addition);
    formData.append('zip', data.zip);
    formData.append('region', data.region);
    formData.append('country', data.country);
    formData.append('pre_select', data.pre_select);
    formData.append('phone', data.phone);
    formData.append('pre_select_mobile', data.pre_select_mobile);
    formData.append('mobile', data.mobile);
    formData.append('pre_select_fax', data.pre_select_fax);
    formData.append('thumb-delete', data.thumbDelete);
    formData.append('icon-delete', data.iconDelete);  
    formData.append('id', this.user_id);
    formData.append('token', this.token);

    return this.post(this.url + "member/profile/update", formData);
  }

  updatePreferences(data): Observable<any> {
    return this.post(this.url + "member/preferences/update", data);
  }

  /**********************
   * Adverts
  **********************/

  getAdvert(id): Observable<any> {
    return this.get(this.url + "advert/" + id + "?token=" + this.token + "&user_id=" + this.user_id);
  }

  getAdverts(): Observable<any> {
    return this.get(this.url + "adverts?token=" + this.token + "&id=" + this.user_id);
  }  

  postNewAdvert(advert): Observable<any> {
    const formData = new FormData();

    if(advert.file) {
      formData.append('file', advert.file, `myfile.${advert.file_ext}`);
    } else {
      formData.append('file_id', advert.file_id);      
    }

    formData.append('name', advert.name);
    formData.append('id', this.user_id);
    formData.append('token', this.token);
    formData.append('phone', advert.phone);
    formData.append('url', advert.url);
    formData.append('email', advert.email);
    formData.append('description', advert.description);

    return this.post(this.url + "adverts/create", formData);
  }

  postNewAdvertImage(advert, file = null, ext = null) {
    const formData = new FormData();
    if(file) {
      formData.append('file', file, `myimage.${ext}`);
    } else {
      formData.append('file_id', advert.file_id);
    }
    formData.append('name', advert.name);
    formData.append('id', this.user_id);
    formData.append('token', this.token);
    formData.append('phone', advert.phone);
    formData.append('url', advert.url);
    formData.append('email', advert.email);
    formData.append('description', advert.description);

    const response = this.httpClient.post(this.url + "advert/create", formData);
    return response;
  }

  postNewAdvertFile(advert, file = null) {
    const formData = new FormData();

    if(file) {
      const ext = file.name.split('.').pop();
      formData.append('file', file, `myimage.${ext}`);      
    } else {
      formData.append('file_id', advert.file_id);
    }

    formData.append('name', advert.name);
    formData.append('id', this.user_id);
    formData.append('token', this.token);
    formData.append('phone', advert.phone);
    formData.append('url', advert.url);
    formData.append('email', advert.email);
    formData.append('description', advert.description);
 
    return this.httpClient.post(this.url + "advert/create", formData);
  }

  postUpdateAdvert(advert): Observable<any> {
    return this.post(this.url + "advert/" + advert.id, { 
        id: this.user_id, 
        token: this.token, 
        name: advert.name,
        phone: advert.phone,
        url: advert.url,
        email: advert.email,
        description: advert.description
      });
  }

  postUpdateAdvertImage(advert, file = null, ext = null) {
    const formData = new FormData();
    if(file) {
      formData.append('file', file, `myimage.${ext}`);
    } else {
      formData.append('file_id', advert.file_id);
    }
    formData.append('name', advert.name);
    formData.append('id', this.user_id);
    formData.append('token', this.token);
    formData.append('phone', advert.phone);
    formData.append('url', advert.url);
    formData.append('email', advert.email);
    formData.append('description', advert.description);
 
    return this.httpClient.post(this.url + "advert/" + advert.id, formData);
  }

  postUpdateAdvertFile(advert, file = null) {
    const formData = new FormData();

    if(file) {
      const ext = file.name.split('.').pop();
      formData.append('file', file, `myimage.${ext}`);      
    } else {
      formData.append('file_id', advert.file_id);
    }

    formData.append('name', advert.name);
    formData.append('id', this.user_id);
    formData.append('token', this.token);
    formData.append('phone', advert.phone);
    formData.append('url', advert.url);
    formData.append('email', advert.email);
    formData.append('description', advert.description);
 
    return this.httpClient.post(this.url + "advert/" + advert.id, formData);
  }

  deleteAdvert(id): Observable<any> {
    return this.get(this.url + "advert/" + id + "/delete?token=" + this.token + "&id=" + this.user_id);
  }  

  /**********************
   * Authentication
  **********************/
  postLogin(username, password): Observable<any> {
    return this.post(this.url + "login", { username: username, password: password });
  }

  passwordReset(password, passwordConfirm): Observable<any> {
    return this.post(this.url + "password", { "token": this.token, "password": password, "password-confirm": passwordConfirm });
  }

  getLogout(): Observable<any> {
    return this.get(this.url + "logout?token=" + this.token);
  }

  postLoginToken(user_id, token): Observable<any> {
    return this.get(this.url + "auth?id=" + user_id + "&token=" + token + "&type=member");
  }

  async setData(data) {
    this.token = data.token;
    this.user_id = data.id;
    
    await Preferences.set({
      key: 'token',
      value: data.token
    });
    await Preferences.set({
      key: 'user_id',
      value: data.id
    });
  }

  /**********************
   * Helper
  **********************/
  showToast(message) {
      this.h.showToast(message);
  }

  async showLoader() {
    let loader = await this.h.showLoader();
    return loader;
  }

  async hideLoader() {
    let loader = await this.h.dismissLoader();
    return loader;
  }

  /**********************
   * Location
  **********************/
  getLocation(id): Observable<any> {
    return this.get(this.url + "member/location/" + id + "?token=" + this.token + "&user_id=" + this.user_id);
  }

  requestLocation(): Observable<any> {
    return this.get(this.url + "member/request/location" + "?token=" + this.token + "&user_id=" + this.user_id);
  }

  updateLocation(location): Observable<any> {
    const formData = new FormData();

    formData.append('addr_addition', location.addr_addition);
    formData.append('city', location.city);
    formData.append('comment', location.comment);
    formData.append('country', location.country);
    formData.append('email', location.email);
    formData.append('fax', location.fax);

    if(location.icon) {
      formData.append('icon', location.icon,  `myfile.${location.icon_ext}` );
    }

    formData.append('mobile', location.mobile);
    formData.append('phone', location.phone);
    formData.append('pre_select', location.pre_select);
    formData.append('pre_select_fax', location.pre_select_fax);
    formData.append('pre_select_mobile', location.pre_select_mobile); 
    formData.append('region', location.region);
    formData.append('street', location.street);
    formData.append('title', location.title);
    formData.append('url', location.url);
    formData.append('zip', location.zip);

    formData.append('user_id', this.user_id);
    formData.append('token', this.token);

    console.log(location.icon);
    return this.post(this.url + "member/location/" + location.id + "/update", formData);
  }
}