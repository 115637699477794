import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LeaveGuardService } from './leave-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'profile',
    canDeactivate: [LeaveGuardService],
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'tickets',
    loadChildren: () => import('./tickets/tickets.module').then( m => m.TicketsPageModule)
  },
  {
    path: 'ticket/:id',
    loadChildren: () => import('./ticket/ticket.module').then( m => m.TicketPageModule)
  },
  {
    path: 'ads',
    loadChildren: () => import('./ads/ads.module').then( m => m.AdsPageModule)
  },
  {
    path: 'ad/:id',
    canDeactivate: [LeaveGuardService],
    loadChildren: () => import('./ad/ad.module').then( m => m.AdPageModule)
  },
  {
    path: 'education',
    loadChildren: () => import('./education/education.module').then( m => m.EducationPageModule)
  },
  {
    path: 'event/:id',
    loadChildren: () => import('./event/event.module').then( m => m.EventPageModule)
  },
  {
    path: 'quizzes',
    loadChildren: () => import('./quizzes/quizzes.module').then( m => m.QuizzesPageModule)
  },
  {
    path: 'quiz/:id',
    loadChildren: () => import('./quiz/quiz.module').then( m => m.QuizPageModule)
  },
  {
    path: 'recordings',
    loadChildren: () => import('./recordings/recordings.module').then( m => m.RecordingsPageModule)
  },
  {
    path: 'recording/:id',
    loadChildren: () => import('./recording/recording.module').then( m => m.RecordingPageModule)
  },
  {
    path: 'downloads',
    loadChildren: () => import('./downloads/downloads.module').then( m => m.DownloadsPageModule)
  },
  {
    path: 'documents',
    loadChildren: () => import('./documents/documents.module').then( m => m.DocumentsPageModule)
  },
  {
    path: 'templates',
    loadChildren: () => import('./templates/templates.module').then( m => m.TemplatesPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'prints',
    loadChildren: () => import('./prints/prints.module').then( m => m.PrintsPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'print',
    loadChildren: () => import('./print/print.module').then( m => m.PrintPageModule)
  },
  { 
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'location/:id',
    canDeactivate: [LeaveGuardService],
    loadChildren: () => import('./location/location.module').then( m => m.LocationPageModule)
  },
  {
    path: 'locations',
    loadChildren: () => import('./locations/locations.module').then( m => m.LocationsPageModule)
  },
  {
    path: 'ad-new',
    loadChildren: () => import('./ad-new/ad-new.module').then( m => m.AdNewPageModule)
  },
  {
    path: 'ticket-new',
    loadChildren: () => import('./ticket-new/ticket-new.module').then( m => m.TicketNewPageModule)
  },
  {
    path: 'preferences',
    loadChildren: () => import('./preferences/preferences.module').then( m => m.PreferencesPageModule)
  },
  {
    path: 'password-change',
    loadChildren: () => import('./password-change/password-change.module').then( m => m.PasswordChangePageModule)
  },
  {
    path: 'educations',
    loadChildren: () => import('./educations/educations.module').then( m => m.EducationsPageModule)
  },
  {
    path: 'educations-new',
    loadChildren: () => import('./educations-new/educations-new.module').then( m => m.EducationsNewPageModule)
  },
  {
    path: 'bills',
    loadChildren: () => import('./bills/bills.module').then( m => m.BillsPageModule)
  },
  {
    path: 'posts',
    loadChildren: () => import('./posts/posts.module').then( m => m.PostsPageModule)
  },
  {
    path: 'post/:id',
    loadChildren: () => import('./post/post.module').then( m => m.PostPageModule)
  },
  {
    path: 'advantages',
    loadChildren: () => import('./advantages/advantages.module').then( m => m.AdvantagesPageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then( m => m.EventsPageModule)
  },
  {
    path: 'certificates',
    loadChildren: () => import('./certificates/certificates.module').then( m => m.CertificatesPageModule)
  },
  {
    path: 'magazines',
    loadChildren: () => import('./magazines/magazines.module').then( m => m.MagazinesPageModule)
  },
  {
    path: 'modal-print',
    loadChildren: () => import('./modal-print/modal-print.module').then( m => m.ModalPrintPageModule)
  },
  {
    path: 'advantages-modal',
    loadChildren: () => import('./advantages-modal/advantages-modal.module').then( m => m.AdvantagesModalPageModule)
  },
  {
    path: 'notes',
    loadChildren: () => import('./notes/notes.module').then( m => m.NotesPageModule)
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then( m => m.ShopPageModule)
  },
  {
    path: 'product',
    loadChildren: () => import('./product/product.module').then( m => m.ProductPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'podcasts',
    loadChildren: () => import('./podcasts/podcasts.module').then( m => m.PodcastsPageModule)
  },
  {
    path: 'cart-end',
    loadChildren: () => import('./cart-end/cart-end.module').then( m => m.CartEndPageModule)
  },
  {
    path: 'podcast/:id',
    loadChildren: () => import('./podcast/podcast.module').then( m => m.PodcastPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  providers: [ 
    LeaveGuardService
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
