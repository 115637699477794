import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  toast: any;
  loader: any;
  constructor(public router: Router, public loadingController: LoadingController, public toastController: ToastController) {}
 
  showToast(message) {
    this.toast = this.toastController.create({
      color: 'primary',
      message: message,
      duration: 4000
    }).then((toastData)=>{
        toastData.present();
    });
  }  

  to(url) {
    this.router.navigate([url]);
  }

  async showLoader() {
    this.loader = await this.loadingController.create({
      message: 'Lade...'
    });
    await this.loader.present();
    return this.loader;
  }  

  async dismissLoader() {
    await this.loadingController.dismiss();
    return;
  }  

  getToday() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    let today_string = yyyy + '-' + mm + '-' + dd;
    return today_string;
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
 
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
 
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
 
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
 
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}