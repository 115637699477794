import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DataService } from './data.service';
import { Plugins } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Preferences } from '@capacitor/preferences';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public unreadCounter = 0;
  public selectedIndex = 1;  
  public appPages = [
    { title: 'Dashboard', url: '/dashboard', icon: 'home' },
    { title: 'Praxiseinträge', url: '/locations', icon: 'storefront' },
    { title: 'Neuigkeiten', url: '/posts', icon: 'newspaper' },
    { title: 'Veranstaltungen', url: '/events', icon: 'calendar' },
    { title: 'Fortbildungspunkte', url: '/education', icon: 'library' },
    { title: 'Kommunikation', url: '/tickets', icon: 'chatbubble' },
    { title: 'Shop', url: '/shop', icon: 'cart' },
    { title: 'Podcasts', url: '/podcasts', icon: 'mic' },
    { title: 'Dokumente', url: '/documents', icon: 'cloud-download' },
    { title: 'Pinboard', url: '/ads', icon: 'pin' },
    { title: 'Vorteile', url: '/advantages', icon: 'gift' },
    { title: 'Profil', url: '/profile', icon: 'person' },
    { title: 'Präferenzen', url: '/preferences', icon: 'bookmarks' }
  ];
  
  constructor(private platform: Platform, private ds: DataService) {
    this.initializeApp();
  }

  updateMenuState(state: boolean) {
    console.log("Update Menu Status!");
    this.checkUnread();
  }

  async checkUnread() {
    var self = this,
        data = await this.ds.checkUnread();

    data.subscribe(function(response) {
      console.log(response);
      if(response.code == 200) {
        self.unreadCounter = response.count;
        console.log(self.unreadCounter); 
      } 
    });    
  }

  initializeApp() {
    var self = this;
    (window as any).is_dirty = false;
    this.platform.ready().then(() => {
      SplashScreen.hide();
      StatusBar.setStyle({ style: Style.Light });
      this.ds.restoreData();
      setTimeout(function() {
        self.checkUnread();
      }, 1000);
    });
  }

  changeMode() {
    this.ds.changeMode();
  }
}