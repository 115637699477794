import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, Router} from "@angular/router";
import { Observable } from "rxjs";
import { AlertController } from '@ionic/angular';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({ providedIn: 'root' })

export class LeaveGuardService implements CanDeactivate<CanComponentDeactivate> {
  alert: any;

  constructor(public alertController: AlertController, private router: Router) {
     console.log("Can deactivate Service starts");
  }

  public async canDeactivate(component: CanComponentDeactivate, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if((window as any).is_dirty) {
      await this.confirmDeactivate();
      const data = await this.alert.onDidDismiss();
      if(data.role == "confirm") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private async confirmDeactivate() {
    this.alert = await this.alertController.create({
        header: 'Wirklich schließen?',
        message: 'Sie haben Ihre Änderungen nicht gespeichert - alle Änderungen gehen verloren.',
        buttons: [{
            text: 'Abbrechen',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
                return false;
            }
        }, {
            text: 'Schließen',
            role: 'confirm',
            handler: () => {
              (window as any).is_dirty = false;
              return true;
            }
        }]
    });

    await this.alert.present();
  }
}
